<template>
  <div class="container">
    <a-descriptions bordered :column="2">
      <a-descriptions-item :span="2">
        <div slot="label" class="center">标段名称</div>
        <div>
          {{ detail.name }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item :span="2">
        <div slot="label" class="center">开标地点</div>
        <div>
          {{ detail.bidAddress }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">招标方式</div>
        <div>
          <DataDictFinder dictType="bid_method" :dictValue="detail.bidMethod" />
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">标底价(元)</div>
        <div>
          {{ detail.basePrice }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">所属地区</div>
        <div>
          {{ detail.area }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">招标编号</div>
        <div>
          {{ detail.code }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">招标业主</div>
        <div>
          {{ detail.contact }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">招标阶段</div>
        <div>
          <DataDictFinder
            dictType="bid_item_status"
            :dictValue="detail.status"
          />
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">开标日期</div>
        <div>
          {{ detail.bidDate }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">截标日期</div>
        <div>
          {{ detail.deadline }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">是否EPC项目</div>
        <div>
          <span v-if="detail.isEpc">是</span>
          <span v-else>否</span>
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">项目编号</div>
        <div>
          {{ detail.epcProjectCode }}
        </div>
      </a-descriptions-item>

      <a-descriptions-item>
        <div slot="label" class="center">供应商</div>
        <div>
          {{ detail.invitedCompany }}
        </div>
      </a-descriptions-item>
    </a-descriptions>

    <Padding size="large" />

    <a-descriptions bordered :column="1">
      <a-descriptions-item>
        <div slot="label" class="center">采购项（标段）</div>
        <div>
          {{ detail.name }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">主要内容</div>
        <div>
          {{ detail.purchaseContent }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">数量及单位</div>
        <div>
          {{ detail.purchaseNumber }}
        </div>
      </a-descriptions-item>
      <a-descriptions-item>
        <div slot="label" class="center">要求到货时间</div>
        <div>
          {{ detail.purchaseArrivalDate }}
        </div>
      </a-descriptions-item>
    </a-descriptions>
  </div>
</template>

<script>
import { fetchDetail } from "@/api/bidding";
export default {
  data() {
    return {
      detail: {},
    };
  },

  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    if (id) {
      this.getDetail(id);
    }
  },

  methods: {
    getDetail(id) {
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;
}
</style>